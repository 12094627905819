export const HOME_DATA = [
  {
    id: "module-introduction",
    path: "0-introduction",
    name: 'INTRODUCTION'
  },
  {
    id: "module-app",
    path: "1-app",
    name: 'APP'
  },
  {
    id: "module-web",
    path: "2-web",
    name: 'WEB'
  },
  {
    id: "module-activity",
    path: "3-activity",
    name: '运营设计'
  }
]