import React from 'react'
import './styles.styl'
import Link from '../link-html'
import { ConfigContext } from "../config-context"
import { scrollToY } from '../../utils/scroll-to-y'
import dynamic from 'next/dynamic'
import { HOME_DATA } from "../../utils/data"

const DarkModeToggle = dynamic(() => import('../dark-mode-toggle-fm'), { ssr: false })
const ScrollspyNav = dynamic(() => import('../scrollspy-nav'), { ssr: false })

export default function Header() {
  const [isOpen, setIsOpen ] = React.useState(false)

  const { colorMode, setColorMode } = React.useContext(ConfigContext)

  const isDark = colorMode === 'dark';
  let mannualScrolling = false

  function toggleColorMode() {
    // event.preventDefault();
    setColorMode(isDark ? 'light' : 'dark');
  }

  const toggleMenuStatus = () => setIsOpen(!isOpen)

  // 切换导航
  const goTab = (e) => {
    e.preventDefault()

    if (mannualScrolling) return
    
    const hash = e.target.hash || ''
    const moduleNow = document.getElementById(hash.substr(1))
    const offsetTop = moduleNow ?  moduleNow.offsetTop : 0
    const tabHeight = document.getElementById('header-holder').offsetHeight

    mannualScrolling = true

    scrollToY({
      scrollTargetY: offsetTop - tabHeight,
      speed: 300,
      easing: 'easeInOutQuint',
      endCallback: () => {
        setTimeout(() => {
          mannualScrolling = false
        }, 100)
      }
    })

    return false
  }

  const renderMainNav = (isPC) => {

    return (
      <nav className={isPC ? 'navigation-pc' : 'navigation-mobile'}>
        <ScrollspyNav
          offset={-84}
          scrollTargetIds={HOME_DATA.map(item => item.id)}
          activeNavClass="is-active"
          scrollDuration={300}
        >
          <ul>
            {
              HOME_DATA.map(item => {
                const { id, name } = item
                return (
                  <li key={id}>
                    <a href={'#' + id}>{name}</a>
                  </li>
                )

              })
            }
          </ul>
        </ScrollspyNav>
      </nav>
    )
  }

  const renderMainOperation = () => {
    const modeTitle = '切换颜色模式'

    return (
      <div className="navigation-operation">
        <button className={"mode "} title={modeTitle} aria-label={modeTitle} onClick={toggleColorMode}>
          <DarkModeToggle checked={isDark} sunColor='white' moonColor='white' />
        </button>
        <a className='button' href='https://www.xiaoxili.com' title='小溪里技术站' target="_blank" rel="noreferrer" aria-label='小溪里技术站'>
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <title>小溪里技术站</title>
            <g id="logo-动画" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="xiaoxili-little" fill="#FFFFFF">
                <path d="M2.36469003,10.822923 C2.64895138,10.2188242 3.26859869,9.50330638 3.6277201,9.11549019 C3.77032092,8.96150327 4.0296916,8.96150327 4.17229242,9.11549019 C4.53142726,9.50330638 5.15106113,10.2188242 5.43532249,10.822923 C5.59045595,11.1052262 5.65772657,11.4346678 5.59464693,11.7835185 C5.46972346,12.4744073 4.84043152,13.0133431 4.07659835,13.0828559 C3.04839755,13.1764219 2.18181818,12.4423159 2.18181818,11.5235775 C2.18181818,11.2714051 2.24869926,11.0339856 2.36469003,10.822923 Z" id="路径" fillRule="nonzero" transform="translate(3.900000, 11.045455) scale(-1, 1) translate(-3.900000, -11.045455) "></path>
                <path d="M7.47588827,6.12331431 C8.32867233,4.31101801 10.1876142,2.16446459 11.2649785,1.00101603 C11.6927809,0.539055264 12.470893,0.539055264 12.8986954,1.00101603 C13.9761,2.16446459 15.8350016,4.31101801 16.6877856,6.12331431 C17.153186,6.97022399 17.3549979,7.95854874 17.165759,9.00510105 C16.7909886,11.0777672 14.9031128,12.6945747 12.6116132,12.9031132 C9.52701084,13.1838113 6.92727273,10.9814932 6.92727273,8.22527805 C6.92727273,7.46876078 7.12791595,6.75650235 7.47588827,6.12331431 Z" id="路径" fillRule="nonzero" transform="translate(12.081818, 6.790909) scale(-1, 1) translate(-12.081818, -6.790909) "></path>
                <path d="M17.4540682,2.17014637 C17.7924746,1.45098117 18.53015,0.599174262 18.9576754,0.137488322 C19.1274383,-0.0458294407 19.4362129,-0.0458294407 19.6059758,0.137488322 C20.0335173,0.599174262 20.7711767,1.45098117 21.109583,2.17014637 C21.2942657,2.50622164 21.3743498,2.898414 21.299255,3.31371254 C21.1505366,4.13619911 20.4013795,4.7777894 19.4920543,4.86054277 C18.2680057,4.97193089 17.2363636,4.09799515 17.2363636,3.00425897 C17.2363636,2.7040537 17.315984,2.42141147 17.4540682,2.17014637 Z" id="路径" fillRule="nonzero" transform="translate(19.281818, 2.435065) scale(-1, 1) translate(-19.281818, -2.435065) "></path>
                <path d="M6.22364938,5.11291484 C6.22364938,5.11291484 8.62092606,4.99983878 10.7767288,6.00347616 C12.6127774,6.85825152 13.7443734,8.20348059 13.7443734,8.20348059 C13.7443734,8.20348059 14.7792473,9.4525093 15.0291784,10.7202336 C15.0760638,10.95805 15.3686201,11.6366001 14.8533013,13.4335979 C14.7489588,13.7974566 13.4658394,16.5691489 13.5786918,18.0298521 C13.6915441,19.4905553 14.1291116,20.7249296 14.5654118,21.896528 C14.7874916,22.4928796 17.3161053,28.0795734 18.1479936,28.5233413 C18.5568251,28.741431 14.5423949,28.5355284 10.2503015,25.515231 C9.58859482,25.0495954 8.14494588,23.8551392 7.16684339,22.278694 C6.73107687,21.5763524 5.4798797,19.4014397 5.93418984,17.3284927 C6.38849997,15.2555457 8.92901883,13.4169312 9.81722835,12.2258698 C10.2491738,11.6466444 10.561652,11.11741 10.4652159,9.81656102 C10.3687799,8.51571205 9.69273024,7.69269193 9.45795223,7.37950904 C7.83889231,5.21975885 6.22364938,5.11291484 6.22364938,5.11291484 Z" id="路径-2" transform="translate(12.006560, 16.847587) rotate(-89.000000) translate(-12.006560, -16.847587) "></path>
              </g>
            </g>
          </svg>
        </a>
        {/* <button className="language"></button>
        <button className="subscribe"></button> */}
      </div>
    )
  }

  return (
    <>
      {/* 头部 */}
      <header className="header">
        <div className="header-holder" id='header-holder'></div>
        <div className="header-outer">
          <div className="header-main">
            <h1 className="logo">
              <Link href='/' addHtml={false}>
                不二雪 buerxue.com
                </Link>
            </h1>
            {renderMainNav(true)}
            {renderMainOperation()}
            {/* 杠杠图标 */}
          </div>

        </div>
        <button
          className={"navigation-toggle " + (isOpen ? 'active' : '')}
          onClick={toggleMenuStatus}
          aria-label={isOpen ? '关闭菜单' : '打开菜单'}
        >
          <div className='text'>菜单切换</div>
        </button>
        <div
          className={'navigation-pop ' + (isOpen ? 'active' : '')}
        >
          <button className="navigation-pop-btn" onClick={toggleMenuStatus} aria-label={'关闭菜单'}></button>
          {renderMainNav()}
          {renderMainOperation()}
        </div>
      </header>
      {/* 导航菜单 */}
    </>
  )
}