import Head from 'next/head'

export default function DocumentMeta(props) {
  const { title = '不二雪（夏雪）的作品集' } = props

  return (
    <Head>
      {title ? <title>{title || ''}</title> : ''}
      <meta content="UI Design,不二雪,夏雪,作品集" name="Keywords" />
      <meta name="description" content="不二雪的作品集。不二雪，UI设计师，擅长APP、Web、运营活动等内容的设计" />
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width,minimum-scale=1.0, viewport-fit=cover" />
    </Head>
  )
}